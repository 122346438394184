import React, { useState } from "react";
import AxiosConfig from "../../../Common/Api/AxiosConfig";
import { FileUploader } from "react-drag-drop-files";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import useButtonLoader from "../../ButtonLoaderHook";

const validationSchema = Yup.object().shape({
  file: Yup.mixed()
    .required("Excel file is required")
    .nullable()
    .test(
      "fileType",
      "Only accept xls/xlsx file type",
      (file) =>
        file &&
        [
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ].includes(file.type)
    ),
});

const BulkImport = () => {
  const history = useHistory();
  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Upload File",
    "Loading..."
  );
  const [file, setFile] = useState(null);
  const fileTypes = ["xlsx", "xls"];

  /* --------------------------------- submit --------------------------------- */
  const onSubmitHandler = async (values) => {
    setButtonLoader(true);
    try {
      let data = new FormData();
      data.append("file", values.file);
      const res = await AxiosConfig.post(
        "/kreesa/admin/product/bulk-import",
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (res) {
        setFile(null)
        toast.success("Bulk product uploaded successfully succesfully !");
        setButtonLoader(false);
      }
    } catch (err) {
      toast.error(
        err?.response.data.message ?? "An error occurred during upload."
      );
      if (
        err.response.data.httpStatusCode === 403 ||
        err.response.data.httpStatusCode === 401
      ) {
        localStorage.clear();
        history.push("/admin/login");
      }
      setButtonLoader(false);
    }
  };

  return (
    <Formik
      initialValues={{
        file: null,
      }}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
    >
      {({
        handleChange,
        handleSubmit,
        values,
        errors,
        touched,
        isValid,
        handleBlur,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="col">
            <div className="mb-3 col-md-5">
              <label className="form-label" htmlFor="inputCity">
                <span className="font-weight-bold">Upload Excel File</span>
              </label>
              <FileUploader
                fileOrFiles={file}
                handleChange={(file) => {
                  setFieldValue("file", file);
                  setFile(file);
                }}
                uploadedLabel={file?.name}
                name="file"
                types={fileTypes}
              />
              {errors.file && touched.file ? (
                <div style={{ color: "red" }}>{errors.file}</div>
              ) : null}
            </div>
            {/* <div className="text-muted h5">
              Brand Image must be 512 x 512 (pixels) Dimension
            </div> */}
          </div>

          <div className="mb-3 mt-4 col-md-5">
            <button
              type="submit"
              className="btn btn-success btn-lg btn-block UppyInput-Progress"
              ref={buttonLoader}
              //   disabled={!isValid}
            >
              Upload File
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default BulkImport;
