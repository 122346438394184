import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Spinner from "../../../../Admin/Spinner";
import {
  approveCustomOrder,
  getCustomOrder,
  rejectCustomOrder,
} from "../../../Features/Action/CustomOrderAction";
import AxiosConfig from "../../../../../Common/Api/AxiosConfig";
import { toast } from "react-toastify";
import HtmlParser from "react-html-parser";
import { Excerpt } from "../../../../../utils";
import { Pagination } from "@mantine/core";

const AllCustomOrdersTab = ({ type }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7);
  const dispatch = useDispatch();

  // to get custom Order
  const { customOrder, loader } = useSelector((s) => s.CustomOrder);

  const orders = customOrder.filter((p) => p.fileType === type.toUpperCase());

  useEffect(() => {
    dispatch(getCustomOrder());
  }, []);

  // to approve custom order
  const handleApproveCustomOrder = (id) => {
    dispatch(approveCustomOrder(id));
    toast.info("Custom Order has been Approved");
    // window.location.reload();
  };

  // to reject custom order
  const handleRejectCustomOrder = (id) => {
    dispatch(rejectCustomOrder(id));
    toast.error("Reject custom Order");
    // window.location.reload();
  };

  const deleteCustomOder = (id) => {
    dispatch(deleteCustomOder(id));
    toast.error("custom Order Deleted");
    // window.location.reload();
  };
  // Pagination
  const indexOfLast = currentPage * perPage;
  const indexOfFirst = indexOfLast - perPage;
  const currentEntries = orders.slice(indexOfFirst, indexOfLast);
  const totalPagesNum = Math.ceil(orders.length / perPage);

  return !loader ? (
    <div>
      <div className="col-12 col-lg-8 d-flex w-100">
        <div className="card flex-fill overflow-auto">
          <div className="card-header">
            <h5 className="card-title mb-0">
              Latest All Custom Orders ({orders?.length})
            </h5>
          </div>

          <table className="table table-hover my-0">
            <thead>
              <tr>
                <th>Sn.</th>
                <th>Custom Order {type === "image" ? "Picture" : "Voice"}</th>
                <th className="d-xl-table-cell">Last Date</th>
                <th className="d-xl-table-cell">Custom Orders Description</th>
                <th>Status</th>
                <th>Order Status</th>
                <th className="d-md-table-cell">Ordered By</th>
                <th className="d-md-table-cell">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentEntries?.map((p, i) => (
                <tr key={i}>
                  <td>{perPage * (currentPage - 1) + i + 1}</td>
                  <td>
                    {type === "image" ? (
                      <img
                        src={`${AxiosConfig.defaults.baseURL}/kreesa/image/custom/${p.orderId}/${p.order_image}`}
                        width="48"
                        height="48"
                        className="rounded-circle mr-2"
                        alt="Avatar"
                      />
                    ) : (
                      <audio
                        controls
                        src={`${AxiosConfig.defaults.baseURL}/kreesa/public/audio/custom-order/${p.orderId}/${p.voiceMessage}`}
                        style={{ height: 35 }}
                      />
                    )}
                  </td>
                  <td className="d-xl-table-cell">
                    {" "}
                    {moment(p.dueDate).format("ll")}{" "}
                  </td>
                  <td className="d-xl-table-cell">
                    {HtmlParser(Excerpt(p.orderDescription, 40))}
                  </td>
                  {p.Approval === false ? (
                    <td>
                      <span className="badge bg-info">Not Approved Yet</span>
                    </td>
                  ) : (
                    <td>
                      <span className="badge bg-success">Approved</span>
                    </td>
                  )}

                  {p.orderPlacementFlag === false ? (
                    <td>
                      <span className="badge bg-info">Order Not Placed</span>
                    </td>
                  ) : (
                    <td>
                      <span className="badge bg-success">Order Placed</span>
                    </td>
                  )}

                  <td className="d-md-table-cell">
                    {p.orderType === "ROLE_RETAILER"
                      ? "Retailer"
                      : "Wholesaler"}
                  </td>
                  <td>
                    <div className="btn-group">
                      <button
                        type="button"
                        style={{
                          textTransform: "capitalize",
                          backgroundColor: "#3b7ddd",
                        }}
                        className="btn-md btn btn-success dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        More
                      </button>
                      <div className="dropdown-menu text-center">
                        {/* <Link className="dropdown-item">
                            <i className="fas fa-user-edit"></i> Edit
                          </Link> */}
                        <Link
                          to={`/admin/view-customorder/${p.orderId}`}
                          className="dropdown-item cursor-pointer  text-success"
                        >
                          <i className="fas fa-eye "></i> Review
                        </Link>
                        {p.Approval === false && (
                          <>
                            <div className="dropdown-divider" />

                            <div
                              className="dropdown-item cursor-pointer text-info"
                              onClick={() =>
                                handleApproveCustomOrder(p.orderId)
                              }
                            >
                              <i className="fa fa-check"></i> Approve
                            </div>
                          </>
                        )}

                        {p?.orderPlacementFlag === false &&
                          p.Approval === false && (
                            <>
                              <div className="dropdown-divider" />
                              <div
                                className="dropdown-item cursor-pointer text-danger"
                                onClick={() =>
                                  handleRejectCustomOrder(p.orderId)
                                }
                              >
                                <i className="fas fa-ban"></i> Reject
                              </div>
                            </>
                            
                          )}
                           <>
                              <div className="dropdown-divider" />
                              <div
                                className="dropdown-item cursor-pointer text-danger"
                                onClick={() =>
                                  deleteCustomOder(p.orderId)
                                }
                              >
                                <i className="fas fa-trash"></i> Delete
                              </div>
                            </>
                          
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {currentEntries.length === 0 && (
            <p
              style={{
                padding: 22,
                fontSize: 20,
                fontWeight: "inherit",
                textAlign: "center",
              }}
            >
              No Custom Order Found
            </p>
          )}
        </div>
      </div>
      {currentEntries.length > 0 && (
        <Pagination
          onChange={setCurrentPage}
          page={currentEntries}
          total={totalPagesNum}
        />
      )}
      {/* <Pagination
        pages={totalPagesNum}
        setCurrentPage={setCurrentPage}
        currentEntries={currentEntries}
        allEntries={customOrder}
      /> */}
    </div>
  ) : (
    <Spinner />
  );
};

export default AllCustomOrdersTab;
